import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MainLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Map = makeShortcode("Map");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`How to reach us:`}</h2>
    <Map mdxType="Map" /> 
    <ul>
      <li parentName="ul">{`from Milano:`}<ul parentName="li">
          <li parentName="ul">{`SS. 36 (Nuova Valassina) fino a Lecco`}</li>
          <li parentName="ul">{`uscita Lecco Centro / Valsassina`}</li>
          <li parentName="ul">{`seguire le indicazioni per Valsassina`}</li>
          <li parentName="ul">{`a Ballabio, seguire le indicazioni per Introbio/Taceno`}</li>
          <li parentName="ul">{`oltrepassato l'abitato di Introbio, la seconda via sulla sinistra dopo lo stabilimento NORDA`}</li>
          <li parentName="ul">{`proseguire per 200 metri su via XXV Aprile`}</li>
        </ul></li>
    </ul>
    <ul>
      <li parentName="ul">{`from Bergamo:`}<ul parentName="li">
          <li parentName="ul">{`SS. 342 (Briantea) fino a Cisano Bergamasco`}</li>
          <li parentName="ul">{`prendere SS 639 seguendo le indicazioni per Lecco`}</li>
          <li parentName="ul">{`a Lecco via Antonio da Belledo, prendere lo svincolo per la SS36 e seguire indicazioni per Valsassina`}</li>
        </ul></li>
    </ul>
    <ul>
      <li parentName="ul">{`from Sondrio:`}<ul parentName="li">
          <li parentName="ul">{`SS. 36 direzione Lecco`}</li>
          <li parentName="ul">{`uscita Bellano, imboccare la SP 62 in direzione Valsassina`}</li>
          <li parentName="ul">{`proseguire in direzione Taceno/Lecco per circa 15 km`}</li>
          <li parentName="ul">{`a Primaluna, dopo il ristorante "Il cigno", la seconda via sulla destra`}</li>
          <li parentName="ul">{`proseguire per 200 metri su via XXV Aprile`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      